const services = [
  {
    url: require("../img/slider/image-1.jpg"),
    title: "Digital Menu",
    description:
      "Digital restaurant menu: interactive, easy-to-navigate, and visually engaging for a modern dining experience.",
    modal: {
      styles: {
        background: "rgb(155,96,18)",
        backgroundImage:
          "linear-gradient(207deg, rgba(155,96,18,1) 0%, rgb(59, 59, 59) 30%)",
      },
      title: "🚀Bring Your Vision to Life!",
      para: "I create custom digital menus that enhance your restaurant, bar, or café's experience, making ordering simple and engaging.",
      services: [
        "-Custom Designs – Stylish menus that match your brand.",
        "-Mobile & Tablet Friendly – Easy access on any device.",
        "-Quick Updates – Change items and prices anytime.",
        "-QR Code Integration – Instant access for customers.",
      ],
    },
  },
  {
    url: require("../img/slider/image-2.jpg"),
    title: "Web Hosting",
    description:
      "Web hosting: reliable, secure, and high-performance solutions to keep your website running smoothly",
    modal: {
      styles: {
        background: "rgb(48,91,117)",
        backgroundImage:
          "linear-gradient(207deg, rgba(48,91,117,1) 0%, rgba(33,34,39,1) 100%)",
      },
      title: "🚀Fast and secure hosting!",
      para: "I provide reliable web hosting solutions to keep your website running smoothly. Whether you're launching a new site or need better hosting.",
      services: [
        "-Fast Load Times – Optimized for speed and performance.",
        "-99.9% Uptime – Your website stays online, always.",
        "-Secure & Backed Up – Protection and regular backups included.",
        "-Scalable Solutions – Hosting that grows with your business.",
      ],
    },
  },

  {
    url: require("../img/slider/image-3.jpg"),
    title: "Custom websites",
    description:
      "Custom websites: bespoke designs, intuitive navigation, and optimal performance for a standout digital experience.",
    modal: {
      styles: {
        background: "rgb(123,84,67)",
        backgroundImage:
          "linear-gradient(207deg, rgba(123,84,67,1) 0%, rgb(79, 79, 79) 86%)",
      },
      title: "🎨Unique, Professional, and Built for You!",
      para: "I create custom websites tailored to your business needs. Whether you need a simple site or a full-featured platform, I deliver high-quality solutions.",
      services: [
        "-Custom Designs – Built to match your brand and vision.",
        "-Mobile-Friendly – Looks great on all devices.",
        "-SEO Optimized – Helps you rank higher on Google.",
        "-Fast & Secure – Speed and security guaranteed.",
      ],
    },
  },
  {
    url: require("../img/slider/image-5.jpg"),
    title: "Website Maintenance",
    description:
      "Ensure your website runs smoothly with our maintenance services. We offer regular updates, security checks, performance optimization",
    modal: {
      styles: {
        background: "rgb(64,128,26)",
        backgroundImage:
          "linear-gradient(207deg, rgba(64,128,26,1) 0%, rgb(41, 41, 41) 25%)",
      },
      title: "😎Keep Your Website Running Smoothly!",
      para: "I provide reliable website maintenance to ensure your site stays updated, secure, and performing at its best. I can teach you how to manage your site.",
      services: [
        "-Regular Updates – Keeps your site secure updated.",
        "-Performance Optimization – Faster load times.",
        "-Security Monitoring – Protection against threats.",
        "-Backup & Recovery – Regular backups.",
      ],
    },
  },
];

export default services;
