import { useEffect, useState } from "react";
import whatsappIcon from "../img/icons/w-icon.png";

function WhatAppIcon() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <div className={`whatsapp-icon ${fadeIn && "fade-in"}`}>
      <a href="https://wa.me/8622498300">
        <img src={whatsappIcon} alt="WhatsApp Icon" />
      </a>
    </div>
  );
}

export default WhatAppIcon;
